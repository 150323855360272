var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showPopup
    ? _c(
        "travio-modal",
        { attrs: { zIndex: 99999, width: "350", height: "90" } },
        [
          _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("Session Expired Due to Inactivity"),
          ]),
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c("p", { staticClass: "mb-2" }, [
              _vm._v(
                "You are about to be logged out due to inactivity. To continue your work click resume session."
              ),
            ]),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(_vm._s(_vm.secondsLeftBeforeLogout) + " seconds left"),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "flex flex-wrap justify-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "vs-button",
                {
                  staticClass: "w-full",
                  attrs: { color: "primary", type: "filled" },
                  on: {
                    click: function ($event) {
                      return _vm.resetTimers(true, false)
                    },
                  },
                },
                [_vm._v("Resume Session")]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }