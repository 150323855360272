var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "relative" }, [
    _c(
      "div",
      { staticClass: "vx-navbar-wrapper", class: _vm.classObj },
      [
        _c(
          "vs-navbar",
          {
            staticClass: "vx-navbar navbar-custom navbar-skelton",
            class: _vm.textColor,
            attrs: { color: _vm.navbarColorLocal },
          },
          [
            _c("feather-icon", {
              staticClass: "sm:inline-flex xl:hidden cursor-pointer p-2",
              attrs: { icon: "MenuIcon" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.showSidebar.apply(null, arguments)
                },
              },
            }),
            _c("vs-spacer"),
            _c("search-bar", { staticClass: "mr-4" }),
            _c("profile-drop-down"),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }