var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("img", {
    staticClass: "company-logo-image",
    attrs: { src: require("@/assets/images/logo/header-logo.svg") },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }