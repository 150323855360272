var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "the-navbar__user-meta flex items-center" },
    [
      _c("div", { staticClass: "text-right leading-tight hidden sm:block" }, [
        _c("p", { staticClass: "font-semibold" }, [
          _vm._v(_vm._s(_vm.activeUserInfo.displayName)),
        ]),
        _c("small", [_vm._v(_vm._s(_vm.activeUserInfo.companyRole))]),
      ]),
      _c(
        "vs-dropdown",
        { staticClass: "cursor-pointer", attrs: { "vs-custom-content": "" } },
        [
          _c(
            "div",
            { staticClass: "con-img ml-3" },
            [
              _c("vs-avatar", {
                staticClass: "my-0",
                attrs: { src: _vm.activeUserInfo.photoURL, size: "40px" },
              }),
            ],
            1
          ),
          _c("vs-dropdown-menu", { staticClass: "vx-navbar-dropdown" }, [
            _c(
              "ul",
              { staticStyle: { "min-width": "9rem" } },
              [
                _c(
                  "li",
                  {
                    staticClass:
                      "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                    on: {
                      click: function ($event) {
                        return _vm.routeTo({ name: "account" })
                      },
                    },
                  },
                  [
                    _c("feather-icon", {
                      attrs: { icon: "UserIcon", svgClasses: "w-4 h-4" },
                    }),
                    _c("span", { staticClass: "ml-2" }, [_vm._v("Account")]),
                  ],
                  1
                ),
                _c(
                  "li",
                  {
                    staticClass:
                      "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                    on: {
                      click: function ($event) {
                        return _vm.routeTo({ name: "account-selector" })
                      },
                    },
                  },
                  [
                    _c("feather-icon", {
                      attrs: { icon: "BookmarkIcon", svgClasses: "w-4 h-4" },
                    }),
                    _c("span", { staticClass: "ml-2" }, [_vm._v("Start Page")]),
                  ],
                  1
                ),
                _vm.activeUserInfo.isHostAdmin
                  ? _c(
                      "li",
                      {
                        staticClass:
                          "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                        on: {
                          click: function ($event) {
                            return _vm.routeTo({ name: "admin-crm" })
                          },
                        },
                      },
                      [
                        _c("feather-icon", {
                          attrs: {
                            icon: "ExternalLinkIcon",
                            svgClasses: "w-4 h-4",
                          },
                        }),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v("All Apps"),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("vs-divider", { staticClass: "m-1" }),
                _c("li", { staticClass: "flex py-2 px-4 cursor-none" }, [
                  _c("small", { staticClass: "ml-0 text-muted" }, [
                    _vm._v("Companies"),
                  ]),
                ]),
                _vm._l(_vm.companies, function (company, index) {
                  return _c(
                    "li",
                    {
                      key: "c" + index,
                      staticClass:
                        "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                      class: {
                        "bg-primary": company.id === _vm.activeCompanyId,
                        "text-white": company.id === _vm.activeCompanyId,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.switchComnpany(company)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(company.name)),
                      ]),
                    ]
                  )
                }),
                _c("vs-divider", { staticClass: "m-1" }),
                _c("li", { staticClass: "flex py-2 px-4 cursor-none" }, [
                  _c("small", { staticClass: "ml-0 text-muted" }, [
                    _vm._v("Applications"),
                  ]),
                ]),
                _vm._l(_vm.applications, function (app, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass:
                        "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                      class: {
                        "bg-primary": app.id === _vm.activeApplicationId,
                        "text-white": app.id === _vm.activeApplicationId,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.switchApplication(app)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(app.name)),
                      ]),
                    ]
                  )
                }),
                _c("vs-divider", { staticClass: "m-1" }),
                _c(
                  "li",
                  {
                    staticClass:
                      "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                    on: { click: _vm.logout },
                  },
                  [
                    _c("feather-icon", {
                      attrs: { icon: "LogOutIcon", svgClasses: "w-4 h-4" },
                    }),
                    _c("span", { staticClass: "ml-2" }, [_vm._v("Logout")]),
                  ],
                  1
                ),
              ],
              2
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }