var render = function render(_c, _vm) {
  return _c(
    "footer",
    { staticClass: "the-footer flex-wrap justify-between", class: _vm.classes },
    [
      _c(
        "p",
        [
          _c("span", [_vm._v("Copyright © ")]),
          _c("span", [_vm._v(_vm._s(new Date().getFullYear()) + " ")]),
          _c("feather-icon", {
            attrs: {
              icon: "MapPinIcon",
              svgClasses: "stroke-current text-purple w-3 h-3 mr-1",
            },
          }),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.travelgenix.io",
                target: "_blank",
                rel: "nofollow",
              },
            },
            [_vm._v("travelgenix.io")]
          ),
          _c("span", { staticClass: "hidden sm:inline-block" }, [
            _vm._v(", an Agendas Group Ltd company. All rights reserved."),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }